import axios from 'axios'
import { getToken } from '@/utils/auth'
import store from '@/store'
import { Message } from 'element-ui'

const service = axios.create({
    //baseURL: 'http://localhost:8080/', // url = base url + request url
    baseURL: 'https://api-gsc.meiguibang.com/', // url = base url + request url
    timeout: 30000,
    // headers: { "Content-Type": "application/json;charset=utf-8" },
})

service.interceptors.request.use(
    config => {
        if (store.getters.token) {
            config.headers['token'] = getToken()
        }
        return config
    },
    error => {
        // do something with request error
        console.log(error) // for debug
        return Promise.reject(error)
    }
)

service.interceptors.response.use(
    response => {
        const res = response.data
        // 如果自定义码不是1000，则判断为错误。
        if (res.ret !== 1000) {
            // 如果自定义状态码是50008，50012，50014则重新登录。
            if (res.ret === 50008 || res.ret === 50012 || res.ret === 50014) {
                Message({
                    message: res.msg || 'Error',
                    type: 'error',
                    duration: 2 * 1000,
                    onClose: () => {
                        store.dispatch('admin/resetToken').then(() => {
                            // location.reload()
                        })
                    }
                })
            } else {
                Message({
                    message: res.msg || 'Error',
                    type: 'error',
                    duration: 4 * 1000
                })
            }
            return Promise.reject(new Error(res.msg || 'Error'))
        } else {
            return res
        }
    },
    error => {
        console.log('err' + error) // for debug
        Message({
            message: error.message,
            type: 'error',
            duration: 5 * 1000
        })
        return Promise.reject(error)
    }
)

export default service